/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// require('bootstrap/dist/js/bootstrap.bundle.min.js');

exports.onServiceWorkerUpdateReady = () => {
  // window.location.reload(true);
};

exports.onInitialClientRender = () => {
  // facebook
  const facebook = document.createElement('script');
  facebook.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');window.fbq('init', '${704311183679552}');window.fbq('track', 'PageView');`;
  const appendFacebook = () => document.body.appendChild(facebook);

  setTimeout(() => {
    window['requestIdleCallback'] ? window.requestIdleCallback(appendFacebook) : appendFacebook();
  }, 1000);

  // hotjar
  const hotjar = document.createElement('script');
  hotjar.innerHTML = `(function(h,o,t,j,a,r) { h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:${1781910},hjsv:${6}}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;
  const appendHotjar = () => document.body.appendChild(hotjar);

  setTimeout(() => {
    window['requestIdleCallback'] ? window.requestIdleCallback(appendHotjar) : appendHotjar();
  }, 1000);

  /*
  // sendpulse
  const sendpulse = document.createElement('script');
  sendpulse.setAttribute('src', '//web.webpushs.com/js/push/bedd1d1fd943a0746985f564372aa69b_1.js');
  sendpulse.setAttribute('defer', 'true');
  sendpulse.setAttribute('charset', 'UTF-8');
  const appendSendpulse = () => document.body.appendChild(sendpulse);

  setTimeout(() => {
    window['requestIdleCallback'] ? window.requestIdleCallback(appendSendpulse) : appendSendpulse();
  }, 40000);*/
};

exports.onRouteUpdate = () => {
  if (typeof window.fbq !== 'undefined') {
    window.fbq('track', 'ViewContent');
  }
};
